.ant-select {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  display: block;

  &:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.375rem;
    border: 1px solid #d0d4d7;
  }
}

.form-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 38px;
  font-size: 1rem;

  .ant-select-selection-placeholder {
    color: #89929a;
  }
}
