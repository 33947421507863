.ant-table-tbody > tr > td {
  border-bottom: 0px solid #f9f9f9;
  color: #7e8299;
  font-weight: 500;
}

.ant-table {
  .ant-table-column-sorters .ant-table-column-title {
    text-transform: uppercase;
  }
  .ant-table-thead {
    tr {
      th {
        padding: 1.44rem 0 1.2rem 1rem;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #132636;

        &::before {
          display: none;
        }
      }
    }
  }
}

.ant-pagination {
  margin: 20px 0;

  ul {
    display: flex;
    align-items: center;
  }

  .ant-pagination-item,
  .ant-pagination-total-text {
    margin-right: 5px;
    line-height: 28px;
  }

  .ant-pagination-item {
    width: 28px;
    height: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    transition: all 0.3s ease;

    a {
      font-size: 14px;
      font-weight: 600;
      padding: 0;
      color: #5a6772;
      transition: all 0.3s ease;
    }

    &:hover {
      background: #e6eef5;
      color: #03529f !important;
    }

    &.ant-pagination-item-active {
      border: none;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 600;

      background: #e6eef5;

      a {
        color: #03529f !important;
      }
    }
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    width: 28px;
    height: 28px;
    min-width: 28px;

    button {
      font-size: 14px;
    }

    &:hover {
      button {
        border-radius: 6px;
        background: #e6eef5;
        color: #5a6772;
      }
    }

    button {
      border: none;
      color: #7e8299;
    }
  }
}

.ant-table-row {
  .ant-table-cell {
    box-sizing: border-box;
    background-color: #fff;
    color: #717d86;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02625rem;
  }

  .ant-table-cell::before {
    display: none;
  }
}

.ant-table-tbody > tr > td {
  background-color: #fff;
  border-bottom: 1px solid #e6eef5;
}

.ant-table-row-expand-icon {
  position: relative;
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.ant-table-row-expand-icon-collapsed {
    &::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTAgMThMMTYgMTJMMTAgNiwxMkw2IDEyTDkgNloiIHN0cm9rZT0iI0I4QkVDMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPgo=);
      left: unset;
      right: unset;
      top: unset;
      width: unset;
      bottom: unset;
      background: none;
      position: static;
      transition: transform 0.3s ease;
    }

    &::before {
      display: none;
    }
  }

  &.ant-table-row-expand-icon-expanded {
    &::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImljX2NoZXZyb24tcmlnaHQiPgo8cGF0aCBpZD0iUGF0aCAzIiBkPSJNNiAxMEwxMiAxNkwxOCAxMCIgc3Ryb2tlPSIjMDM1MjlGIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==);
      transform: rotate(0deg);
      left: unset;
      right: unset;
      top: unset;
      width: unset;
      bottom: unset;
      background: none;
      position: static;
      transition: transform 0.3s ease;
    }

    &::before {
      display: none;
    }
  }
}

.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 0;
  margin-right: 0;
}

.ant-table .ant-table-tbody tr.ant-table-expanded-row > td {
  background: #d9e5f1;
  padding: 0;
}

.ant-table-expanded-row {
  .ant-table-thead {
    tr {
      th {
        padding: 0.625rem 0rem 0.625rem 2rem;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #717d86;
        background: #d9e5f1;

        &::before {
          display: none;
        }
      }
    }
  }

  .ant-table-thead > tr > th {
    background: rgb(217, 230, 242, 0.2);
  }
  .ant-table .ant-table-tbody tr td {
    background: rgb(217, 230, 242, 0.2);
    padding: 0.625rem 0rem 0.625rem 2rem;
  }

  & > td {
    background: #d9e5f1;
  }

  .ant-table table {
    background: #ffffff;
  }
}
