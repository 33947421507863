.ant-modal {
    &-header {
        padding-top: 38px;
        padding-bottom: 38px;
        background-color: #e6eef5;
    }
    
    &-title {
        font-size: 22px;
    }

    &-confirm-body &-confirm-content {
        margin-top: 20px;
        font-size: 15px;
    }

    &-close {
        top: 36px;
        right: 26px;
        background-color: #d9e5f1;
        border-radius: 6px;
    }

    &-close-x {
        width: 32px;
        height: 32px;
        font-size: 20px;
        line-height: 32px;
    }

    &-close-icon {
        color: #03529f;
    }
}