.ant-picker {
    font-size: 22px;
    border-radius: 0.5rem;

    &-range {
        height: 4rem;
    }

    &-separator {
        width: 24px;
        height: 24px;
    }

    &-range &-clear {
        right: 12px;
    }
}
