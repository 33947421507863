@import "/src/styles/ui";

* {
  box-sizing: border-box;
}

:root {
  font-family:
    "Poppins",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100vh;
  margin: 0;
  font-size: 16px;
  -webkit-text-size-adjust: none;
  font-family: "Poppins";
  color: #132636 !important;
  background-color: #ebf1f7 !important;
}

@media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 639px) {
  html {
    font-size: 12px;
  }
}

.ant-table-column-sorter-inner {
  .active {
    color: #03529f;
  }
}

.container {
  width: 90%;
}

.p-outlet {
  flex: 1 1 auto;
  height: 100%;
}

.ant-modal-wrap {
  padding: 1rem;
}

.d-flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}


.route-table {
  width: 100%;
  border-radius: 0.75rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 1.875rem;
}

.add-button.ant-btn {
  height: 3rem;
  border-radius: 0.375rem;
  background: #03529f;
  color: #ffffff;
  font-weight: 600;
  border: none;
}

.add-button.ant-btn:hover {
  filter: brightness(0.9);
}

.ant-input.form-input {
  height: 38px;
  border-radius: 6px;
  border: 1px solid #d0d4d7;
  background: #ffffff;
  font-size: 1rem;

  &::placeholder {
    color: #89929a;
  }

  &.ng-invalid.ng-touched {
    border-color: #d9214e;
  }
}

.ant-input-affix-wrapper.form-input-group {
  height: 38px;
  border-radius: 6px;
  border: 1px solid #d0d4d7;
  background: #ffffff;
  font-size: 1rem;

  &::placeholder {
    color: #89929a;
  }

  &:hover {
    border: 1px solid #d0d4d7;
  }

  .ant-input {
    &.ng-invalid.ng-touched {
      border-color: #d9214e;
    }
  }

  .anticon {
    cursor: pointer;
  }
}

.ant-form-item {
  margin: 0 0 1.25rem 0;
}

.ant-modal.confirm-modal {
  width: 37.9375rem;
  border-radius: 0.75rem;
  overflow: hidden;
  padding-bottom: 0;

  .ant-modal-close {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    top: 35px;
    right: 30px;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
      line-height: 32px;
      background-color: #d9e5f1;
      color: #03529f;
      border-radius: 6px;
    }
  }
  .ant-modal-close:hover {
    filter: brightness(0.9);
  }

  .ant-modal-header {
    height: 99px;
    padding: 33px 30px;
    border-radius: 8px 8px 0px 0px;
    background: #e6eef5;
    display: flex;
    align-items: center;

    .ant-modal-title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .ant-modal-body {
    padding: 36px 30px;
  }

  .ant-modal-footer {
    border-top: 1px solid #e6f5eb;
    padding: 20px 36px;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn {
      height: 2.5rem;
      padding: 0 1rem;
      border-radius: 6px;
      background: #e7e9eb;
      color: #5a6772;
      font-size: 14px;
      font-weight: 500;
      border: none;

      &:last-child {
        background: #d42d56;
        color: #ffffff;
        font-weight: 600;
      }
    }
    .ant-btn:hover {
      filter: brightness(0.9);
    }
  }
}

.auth-bg {
  background: radial-gradient(circle at 0%, #d9e5f1, #d9e5f1 50%, #ebf1f7 50%);
}

.ant-modal.form-modal {
  width: 37.9375rem;
  border-radius: 0.75rem;
  overflow: hidden;
  padding-bottom: 0;

  .ant-modal-close {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    top: 30px;
    right: 30px;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
      line-height: 32px;
      background-color: #d9e5f1;
      color: #03529f;
      border-radius: 6px;
    }
  }

  .ant-modal-close:hover {
    filter: brightness(0.9);
  }

  .ant-modal-header {
    height: 99px;
    padding: 33px 30px;
    border-radius: 8px 8px 0px 0px;
    background: #e6eef5;
    display: flex;
    align-items: center;

    .ant-modal-title {
      font-size: 22px;
      font-weight: 500;
    }
  }

  .ant-modal-body {
    padding: 36px 30px;
    font-size: 1rem;
    font-weight: 400;
  }

  .ant-modal-footer {
    border-top: 1px solid #e6f5eb;
    padding: 20px 36px;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .ant-modal-confirm-btns,
  .ant-modal-footer {
    .ant-btn {
      height: 2.5rem;
      padding: 0 1rem;
      border-radius: 6px;
      background: #e7e9eb;
      color: #5a6772;
      font-size: 14px;
      font-weight: 500;
      border: none;

      &:last-child {
        background: #03529f;
        color: #ffffff;
        font-weight: 600;
      }
    }

    .ant-btn:hover {
      filter: brightness(0.9);
    }
  }
}

.ant-tooltip-arrow {
  display: none;
}

.ant-tooltip-inner {
  color: #132636;
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  border: 1px solid #e7e9eb;
  background: #fff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
}

.btns {
  &-actions {
    display: flex;
    align-items: center;

    button:not(:first-child) {
      margin-left: 10px;
    }

    .ant-btn {
      width: 32px;
      height: 32px;
      padding: 0;
      border-radius: 6px;
      background: #e6eef5;
      border: 1px solid #e6eef5;
      &:hover {
        border-color: #03529f;
      }
    }
  }
}

.ant-switch-checked {
  background: #03529f;
}

.pagination {
  &-btn {
    padding: 0;
    border: none;
    box-shadow: none;

    &:hover {
      border-radius: 6px;
      background: #e6eef5;
    }
  }
}

.ant-table-column-sorters {
  justify-content: flex-start;
  .ant-table-column-title {
    flex: none;
  }
}

.ant-input-affix-wrapper.admins-search {
  height: 38px;
  width: 250px;
  border-radius: 6px;
  border: 1px solid #d0d4d7;
  background: var(--brand-colors-white, #fff);
}

.lc-notification .ant-notification-notice-close {
  top: auto;
  right: 15px;
}

.lc-notification {
  margin-top: 2rem;
  padding: 0.9375rem;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  min-width: 500px;
  display: flex;
  align-items: center;

  &__body {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }

  &__text {
    font-size: 1rem;
    font-weight: 500;
  }

  &.danger {
    background: #d42d56;
  }

  &.success {
    background: #009933;
  }

  &.info {
    background: #132636;
  }

  &.warn {
    background: #feb019;
  }
}

.ant-table-body {
  overflow-x: hidden;
}

.ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  box-shadow: none;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none;
}

.apexcharts-text tspan {
  font-family: "Poppins";
}

.apexcharts-text tspan:active {
  fill: #132636;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #b5b5c3;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}

.lc-tooltip {
  min-width: 100px;
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.65px;

  &__head {
    padding: 5px 10px;
    font-weight: 500;
    background-color: #f3f4f5;
    border-bottom: 1px solid #d0d4d7;
  }

  &__body {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__name {
    font-weight: 400;
    margin-right: 5px;
    position: relative;

    &.consumed,
    &.generated,
    &.starts {
      padding-left: 15px;
    }

    &.consumed::before,
    &.generated::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0;
    }

    &.consumed::before {
      background-color: #feb019;
    }
    &.generated::before {
        background-color: #00e396;
    }
  }

  &__value {
    font-weight: 600;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

h2 {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

.table-border-bottom-0 tr:last-child td,
.table-border-bottom-0 tr:last-child th {
  border-bottom-width: 0;
}